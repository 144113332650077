<template>
  <div v-if="item" class="quiz-question-item" :id="'question-number-'+index">
    <div class="question-number">Câu {{ index + 1 }}</div>
    <vue-mathjax class="question-content" :formula="item.content" :safe="false" />
    <div v-if="item.file" class="text-center py-3">
      <img :src="item.file" alt="image" class="question-image" />
    </div>
    <div v-if="typeQuestion === 'preview' || typeQuestion === 'take'" class="row gutters-custom row-custom row-padding-10 pl-3 question-answer">
      <div v-for="(answer,key) in item.answers" :key="answer.id" :class="checkClassQuestion(answer,key)">
        <div class="d-flex position-relative">
          <div>
            <span class="position-relative float-left d-inline-block" style="height:36px;width:36px;" @click.prevent="answerQuestion(answer.id)">
              <div class="bg-answer-character"></div>
              <span class="answer-character pointer">{{arrayCharacter[key]}}</span>
            </span>
          </div>
          <div class="w-100 pl-2 pt-1">
            <span @click.prevent="answerQuestion(answer.id)">
              <vue-mathjax class="answer-content" :formula="answer.content" :safe="false"/>
            </span>
          </div>
        </div>
      </div>
      <div v-if="typeQuestion === 'take'" class="col-12 order-12 pt-0 text-right">
        <div class="reason-action d-flex align-items-center float-right" @click="reasonQuestion()">
          <img src="/icon/library/quiz/warning.svg" alt="warning" class="pointer pr-1" /> <span class="pt-1 pointer">Báo lỗi</span>
        </div>
      </div>
    </div>
    <div v-else-if="typeQuestion === 'view'" class="row gutters-custom row-custom row-padding-10 pl-3 question-answer">
      <div v-for="(answer,key) in item.answers" :key="answer.id" :class="checkStatusQuestion(answer,key)">
        <div class="d-flex position-relative">
          <div>
            <span class="position-relative float-left d-inline-block" style="height:36px;width:36px;" @click.prevent="answerQuestion(answer.id)">
              <img v-if="checkResultQuestion(answer,key) === 'success'" class="quiz-image-result" src="/icon/library/quiz/success.svg" alt="success" />
              <img v-if="checkResultQuestion(answer,key) === 'fail'" class="quiz-image-result" src="/icon/library/quiz/fail.svg" alt="fail" />
              <div class="bg-answer-character"></div>
              <span class="answer-character pointer">{{arrayCharacter[key]}}</span>
            </span>
          </div>
          <div class="w-100 pl-2 pt-1">
            <span @click.prevent="answerQuestion(answer.id)">
              <vue-mathjax class="answer-content" :formula="answer.content" :safe="false"/>
            </span>
          </div>
        </div>
      </div>
      <div v-if="item.is_correct" class="col-12 quiz-success-icon order-10">
        <img src="/icon/library/quiz/success.svg" alt="success" /> Đúng
      </div>
      <div v-else class="col-12 quiz-fail-icon order-10" :class="{'no-answer': !item.is_answered}">
        <template v-if="item.is_answered"><img src="/icon/library/quiz/fail.svg" alt="fail" /> Sai <span class="quiz-sucess-text">- Đáp án đúng: {{textSuccess}}</span></template>
        <template v-else><img src="/icon/library/quiz/no-answer.svg" alt="fail" /> Bạn đã bỏ trống câu hỏi này <span class="quiz-sucess-text">- Đáp án đúng: {{textSuccess}}</span></template>
      </div>
      <div class="col-12 order-11" v-if="item.suggestion">
        <button class="btn-view-answer" @click="viewSuggestion = !viewSuggestion">Xem lời giải</button>
      </div>
      <div class="col-10 order-12" v-if="viewSuggestion">
        <div class="quiz-suggestion">
          <div class="quiz-suggestion-title">Lời giải chi tiết</div>
          <div class="quiz-suggestion-content">
            <vue-mathjax :formula="item.suggestion" :safe="false"/>
          </div>
          <div class="quiz-suggestion-footer">Đáp án: {{textSuccess}}</div>
        </div>
      </div>
      <div class="col-2 order-12" v-if="viewSuggestion">
        <img src="/icon/library/close.svg" class="pointer" alt="close" @click.prevent="viewSuggestion = false" />
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const arrayCharacter = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n']
const examAnswerResource = new Resource('library/exam/answer')
export default {
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    },
    index: {
      type: Number,
      default () {
        return 0
      }
    },
    examId: {
      type: String,
      default () {
        return ''
      }
    },
    started: {
      type: Boolean,
      default () {
        return false
      }
    },
    typeQuestion: {
      type: String,
      default () {
        return 'preview'
      }
    }
  },
  data () {
    return {
      arrayCharacter,
      hasMaxLength: false,
      processing: false,
      textSuccess: '',
      viewSuggestion: false
    }
  },
  methods: {
    checkClassQuestion (item, key) {
      const arr = []
      if (item.id === this.item.answer_id) {
        arr.push('answer-primary')
      }
      if (item.content.length >= 40) {
        this.hasMaxLength = true
      }
      if (this.hasMaxLength) {
        arr.push('col-sm-12')
      } else {
        arr.push('col-sm-6')
        if (key % 2) {
          arr.push('order-' + (key + 1))
        } else {
          arr.push('order-' + (key - 1 > 0 ? (key - 1) : 0))
        }
      }
      return arr.join(' ')
    },
    checkStatusQuestion (item, key) {
      const arr = []
      if (this.item.is_answered) { // Đã trả lời
        if (item.id === this.item.correct_answer) { // Là đáp án đúng
          this.textSuccess = this.arrayCharacter[key].toUpperCase()
          arr.push('answer-success')
        }
        if (item.id === this.item.answer_id) {
          arr.push('answer-primary')
        }
      } else { // chưa trả lời
        // eslint-disable-next-line no-lonely-if
        if (item.id === this.item.correct_answer) { // Là đáp án đúng
          this.textSuccess = this.arrayCharacter[key].toUpperCase()
          arr.push('answer-none')
        }
      }
      if (item.content.length >= 40) {
        this.hasMaxLength = true
      }
      if (this.hasMaxLength) {
        arr.push('col-sm-12')
      } else {
        arr.push('col-sm-6')
        if (key % 2) {
          arr.push('order-' + (key + 1))
        } else {
          arr.push('order-' + (key - 1 > 0 ? (key - 1) : 0))
        }
      }
      return arr.join(' ')
    },
    checkResultQuestion (item, key) {
      if (this.item.is_correct && this.item.correct_answer === item.id) { // Trả lời đúng
        return 'success'
      } else if (this.item.is_answered && this.item.answer_id === item.id) { // Đã trả lời
        return 'fail'
      } else {
        return 'none'
      }
    },
    answerQuestion (answerId) {
      if (!this.started) { return }
      if (!this.examId) { return }
      if (this.processing) { return }
      this.processing = true
      const data = {
        exam_id: this.examId,
        question_id: this.item.id,
        answer_id: answerId
      }
      examAnswerResource.store(data)
        .then((res) => {
          if (res.status === 200) {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.answer_id = answerId
            // eslint-disable-next-line vue/no-mutating-props
            this.item.is_answered = 1
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    reasonQuestion () {
      this.$emit('reasonQuestion', this.item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .quiz-question-item{
    .question-number{
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #212121;
      padding-bottom:12px;
    }
    .question-content{
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      color: #212121;
    }
    .question-image{
      width:500px;
      margin: auto;
    }
    .question-answer{
      padding-top:14px;
      .reason-action{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 166.49%;
        color: #92A2BF;
      }
      .quiz-image-result{
        position: absolute;
        left: -25px;
        top: 50%;
        width:16px;
        transform: translateY(-50%);
      }
      .bg-answer-character{
        background: #E3E3E3;
        border-radius: 50%;
        width:100%;
        height:100%;
        cursor: pointer;
      }
      .answer-character{
        position: absolute;
        font-weight: bold;
        color: #212121;
        text-transform: uppercase;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        line-height: 166.49%;
      }
      .answer-content{
        left:46px;
        font-size: 16px;
        line-height: 166.49%;
        color: #212121;
        cursor: pointer;
      }
      .answer-primary{
        .bg-answer-character{
          background:#004390;
        }
        .answer-character{
          color:#fff;
        }
        .answer-content{
          color:#004390;
        }
      }
      .answer-success{
        .bg-answer-character{
          background: #73BF44;
        }
        .answer-character{
          color:#fff;
        }
        .answer-content{
          color: #73BF44;
        }
      }

      .answer-none{
        .bg-answer-character{
          background: #73BF44;
        }
        .answer-character{
          color:#fff;
        }
        .answer-content{
          color: #73BF44;
        }
      }
      .quiz-success-icon{
        font-size: 18px;
        line-height: 37px;
        color: #73BF44;
        padding-top:20px;
        img{
          width:16px;
          margin-right:5px;
        }
      }
      .quiz-fail-icon{
        font-size: 18px;
        line-height: 37px;
        color: #FF0000;
        padding-top:20px;
        img{
          width:16px;
          margin-right:5px;
        }
        &.no-answer{
          color: #FF8236;
        }
        .quiz-sucess-text{
          color: #73BF44;
        }
      }
      .btn-view-answer{
        background: #FFFFFF;
        border: 1px solid #004390;
        border-radius: 5px;
        padding:10px 23px;
        font-size: 16px;
        line-height: 70.5%;
        color:#004390;
      }
      .quiz-suggestion{
        padding:16px 24px 24px;
        background: #F9EFE0;
        border-radius: 5px;
        .quiz-suggestion-title,.quiz-suggestion-footer{
          font-size: 16px;
          line-height: 70.5%;
          color: #445975;
          font-weight: bold;
        }
        .quiz-suggestion-content{
          padding:16px 0;
        }
      }
    }
  }
</style>
