<template>
  <b-modal id="quiz-modal-reason" body-class="p-0" hide-header hide-footer v-model="showDialog">
    <div class="content">
      <div class="text-right">
        <img src="/icon/library/close.svg" alt="close" class="pointer" @click="closeModal()" />
      </div>
      <div class="title font-weight-bold">
        Báo lỗi câu hỏi
      </div>
      <div class="title">
        Vui lòng chọn 1 lý do
      </div>
      <div class="list-reason">
        <b-form-group v-if="listReason.length" class="reason-item p-0">
          <b-form-radio-group v-model="reason_id" name="radios-reason" stacked>
            <b-form-radio v-for="item in listReason" :key="item.id" :value="item.id">{{item.name}}</b-form-radio>
            <b-form-radio value="other">Lỗi khác</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <div class="content-reason-other" v-if="reason_id === 'other'">
          <textarea class="w-100" placeholder="Vui lòng ghi rõ lý do" v-model="reason_content"></textarea>
          <div class="reason-preview-image" v-if="reason_image">
            <span class="position-relative text-break">
              {{ fileName }} <img src="/icon/library/quiz/close.svg" alt="close" class="pointer" @click="reason_image = ''" />
            </span>
          </div>
          <div class="reason-image border-top">
            <img src="/icon/library/image.svg" class="pointer" alt="image" @click.prevent="$refs['reason-image'].click()"/>
            <input type="file" ref="reason-image" accept="image/*" hidden @change.prevent="uploadFile"/>
          </div>
        </div>
      </div>
      <div class="submit-reason">
        <button @click.prevent="submitReason">Gửi</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Resource from '~/common/api/resource'
import { getToken } from '~/common/utils/auth'
const reasonListResource = new Resource('library/exam/report/reason')
const reasonResource = new Resource('library/exam/report')
export default {
  props: {
    objectId: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      reason_id: null,
      reason_image: '',
      reason_content: '',
      showDialog: true,
      listReason: [],
      processing: false,
      fileName: ''
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  created () {
    this.loadListReason()
  },
  methods: {
    loadListReason () {
      reasonListResource.list()
        .then((res) => {
          this.listReason = res.data
        })
    },
    uploadFile (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      const file = files[0]
      if (file.size === 0) {
        this.$message({
          showClose: true,
          message: 'File "' + file.name + '" bị lỗi',
          type: 'error'
        })
        return
      }

      const MB = 5
      const arrExt = ['image/png', 'image/jpeg']
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        return false
      }
      const limitSize = MB * 1024 * 1024
      if (file.size > limitSize) {
        this.$message({
          showClose: true,
          message: 'File "' + file.name + '" quá nặng. Không thể upload. Giới hạn ' + MB + 'MB',
          type: 'error'
        })
        return
      }
      if (!arrExt.includes(file.type)) {
        this.$message({
          showClose: true,
          message: 'File "' + file.name + '" không đúng định dạng.',
          type: 'warning'
        })
        return
      }
      this.fileName = file.name
      const formData = new FormData()
      formData.append('file', file)
      const token = getToken()
      const config = {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
      axios.post(process.env.NUXT_ENV_BASE_API_UPLOAD + '/upload/library', formData, config)
        .then((res) => {
          this.reason_image = res.data.data[0].fileDownloadUri
        })
        .finally(() => {
          this.$refs['reason-image'].value = ''
        })
    },
    submitReason () {
      if (this.processing) { return }
      if (!this.reason_id) {
        this.$message({
          type: 'warning',
          message: 'Chưa chọn lý do báo lỗi'
        })
        return
      }
      let data
      if (this.reason_id === 'other') {
        this.reason_content = this.reason_content.trim()
        if (!this.reason_content) {
          this.$message({
            type: 'warning',
            message: 'Chưa nhập nội dung báo lỗi'
          })
          return
        }
        data = {
          question_id: this.objectId,
          reason_content: this.reason_content,
          reason_image: this.reason_image
        }
      } else {
        data = {
          question_id: this.objectId,
          reason_id: this.reason_id
        }
      }
      this.processing = true
      reasonResource.store(data)
        .then((res) => {
          this.$message({
            type: 'success',
            message: 'Đã báo lỗi thành công'
          })
          this.closeModal()
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.data.message
          })
        })
        .finally(() => {
          this.processing = false
        })
    },
    closeModal () {
      this.$bvModal.hide('quiz-modal-reason')
      this.$emit('reasonSuccess')
    }
  }
}
</script>

<style lang="scss">
  #quiz-modal-reason{
    .modal-dialog{
      width:450px;
      max-width: 100%;
      .content{
        padding:12px;
        .title{
          font-size: 20px;
          line-height: 150.7%;
          text-align: center;
          color: #212121;
        }
        .list-reason{
          padding:32px 20px;
          .reason-item{
            .custom-control{
              padding-bottom:10px;
              .custom-control-label{
                cursor: pointer;
                font-weight: normal !important;
                &::before{
                  border: 1px solid #004390;
                  cursor: pointer;
                }
              }
              &:last-child{
                padding-bottom: 0;
              }
            }
          }
          .content-reason-other{
            position: relative;
            background: #FFFFFF;
            border: 1px solid #C4C4C4;
            box-sizing: border-box;
            textarea{
              padding:0px 14px 8px 10px;
              font-style: italic;
              font-weight: normal;
              font-size: 16px;
              line-height: 182.69%;
              color: #76829C;
              border:none;
              height:100px;
              max-height: 100vh;
            }
            .reason-image{
              padding:5px 14px 8px 10px;
              img{
                width: 20px;
              }
            }
            .reason-preview-image{
              padding:5px 14px;
              font-weight: normal;
              font-size: 16px;
              color: #FC4848;
              img{
                position: absolute;
                padding-left: 5px;
                margin-top:5px;
              }
            }
          }
        }
        .submit-reason{
          text-align: center;
          padding-bottom: 32px;
          button{
            background:#004390;
            border-radius: 22.5px;
            width:120px;
            max-width: 100%;
            color:#fff;
            padding:10px 20px;
            border:none;
          }
        }
      }
    }
  }
</style>
